import {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useRef,
} from 'react';

import { Layout } from 'components/Layout';

interface ContextType {
  isEmbedded: boolean;
  openMenu: boolean;
  setOpenMenu: Dispatch<SetStateAction<boolean>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  showLoginModal: boolean;
  setShowLoginModal: Dispatch<SetStateAction<boolean>>;
}

const layoutContext = createContext<ContextType>({
  isEmbedded: false,
  openMenu: false,
  loading: false,
  showLoginModal: false,
  setOpenMenu: () => {},
  setLoading: () => {},
  setShowLoginModal: () => {},
});

export const LayoutProvider = ({
  children,
}: {
  children: React.ReactNode | React.ReactNodeArray;
}) => {
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const isEmbedded = useRef(false);

  if (window.self !== window.top || /WebView/.test(window.navigator.userAgent)) {
    isEmbedded.current = true;
  } else {
    isEmbedded.current = false;
  }

  return (
    <layoutContext.Provider
      value={{
        isEmbedded: isEmbedded.current,
        openMenu,
        loading,
        setOpenMenu,
        setLoading,
        showLoginModal,
        setShowLoginModal,
      }}
    >
      <Layout>{children}</Layout>
    </layoutContext.Provider>
  );
};

export const useLayout = () => useContext(layoutContext);

import { baseAPICreator } from './baseAPICreator';
import { CompanyLogoEntity, ProfileProps } from 'types/profile';
import {
  setValueInSession,
  getValueFromLocal,
  getValueFromSession,
  setValueInLocal,
} from 'utils/storage';
import { convertImageUrlToDataURL } from 'utils/image';
import { formatReorderResponse } from 'utils/orderFields';
import axios from 'axios';
import * as configcat from 'configcat-js';
import { Pronouns } from 'pages/EditQCard/utils/types';
export const configCatClient = configcat.getClient(
  process.env.REACT_APP_CONFIGCAT_SDK_KEY as string
);

export const currentProfileCachePath = () => {
  const auth = getValueFromSession('auth');
  const lastVisitedProfileUrl = localStorage.getItem('lastVisitedProfileUrl');
  let path = '';
  if (auth && auth?.value?.user?.alias) {
    path = auth.value?.user?.alias;
  } else if (lastVisitedProfileUrl) {
    path = lastVisitedProfileUrl;
  }
  return `cachedProfile-${path}`;
};

export const getCurrentUserCachedProfile = () => {
  return getValueFromLocal(currentProfileCachePath());
};
export const convertProfile = (
  data: any,
  isSectionTitleEditable: boolean
): ProfileProps => {
  const formData = {
    uid: data.uid[0]?.value,
    uuid: data.uuid[0]?.value,
    langcode: data.langcode[0]?.value,
    name: data.name[0]?.value ?? '',
    mail: data.mail[0]?.value ?? '',
    created: new Date(data.created[0]?.value),
    changed: new Date(data.changed[0]?.value),
    default_langcode: data.default_langcode[0]?.value,
    metatag: {
      ...data.metatag?.value,
      canonical_url: window.location.origin + data.path[0].alias,
    },
    nfc_card_owner_profiles: data.nfc_card_owner_profiles[0]?.value,
    path: data.path[0],
    field_about_me: data.field_about_me[0]?.value ?? '',
    field_address_1: data.field_address_1[0]?.value,
    field_address_line_2: data.field_address_line_2[0]?.value,
    field_city: data.field_city[0]?.value,
    field_company: data.field_company[0]?.value,
    field_company_entity: data?.field_company_entity?.[0] ?? null,
    field_company_logo: data.field_company_logo[0],
    field_company_logo_square: data.field_company_logo_square[0],
    field_name_pronunciation: data.field_name_pronunciation[0],
    field_gwallet_hero_img: data.field_gwallet_hero_img?.[0],
    field_country: data.field_country[0]?.value,
    field_facebook: data.field_facebook[0]?.value,
    field_first_name: data.field_first_name[0]?.value ?? '',
    field_instagram: data.field_instagram[0]?.value,
    field_last_name: data.field_last_name[0]?.value ?? '',
    field_linkedin: data.field_linkedin[0]?.value,
    field_logo_company_url: data.field_logo_company_url[0]?.value,
    field_messanger: data.field_messanger[0]?.value,
    field_pic_profile_url: data.field_pic_profile_url[0]?.value,
    field_position: data.field_position[0]?.value,
    field_profile_pic: data.field_profile_pic[0],
    field_pronouns: data?.field_pronouns?.[0]?.value ?? Pronouns.NONE,
    field_pronouns_custom: data?.field_pronouns_custom?.[0]?.value ?? '',
    field_qr: data.field_qr[0]?.value,
    field_scanned: data.field_scanned[0]?.value,
    field_shortcut_for_mobile: data.field_shortcut_for_mobile[0]?.value ?? false,
    field_skype: data.field_skype[0]?.value,
    field_state: data.field_state[0]?.value,
    field_telegram: data.field_telegram[0]?.value,
    field_tiktok: data.field_tiktok[0]?.value,
    field_twitter: data.field_twitter[0]?.value,
    field_view_video: data.field_view_video[0]?.value,
    field_whatsapp: data.field_whatsapp[0]?.value,
    field_zip: data.field_zip[0]?.value,
    user_picture: data.user_picture[0]?.value,
    field_colleagues: data.field_colleagues
      ? data.field_colleagues.map((item: any) => item.value)
      : [],
    //comment the code for now as backend is not ready yet
    // field_colleagues_comment: data.field_colleagues_comment
    //   ? data.field_colleagues_comment.map((item: any) => item.value)
    //   : [],
    field_colleagues_message: data.field_colleagues_message[0]?.value,
    field_contact_details_numbers: data.field_contact_details_numbers ?? [],
    field_more_contact_emails: data.field_more_contact_emails ?? [],
    aboutme_section_title: data.field_aboutme_section_title[0]?.value,
    field_work_sched_sect_title: data.field_work_sched_sect_title[0]?.value,
    field_show_home_timezone: data.field_show_home_timezone[0]?.value,
    field_home_timezone: data.field_home_timezone[0]?.value,
    field_show_traveling_timezone: data.field_show_traveling_timezone[0]?.value,
    field_traveling_timezone: data.field_traveling_timezone[0]?.value,
    field_begin_travel_day: data.field_begin_travel_day[0]?.value,
    field_end_travel_day: data.field_end_travel_day[0]?.value,
    field_show_work_schedule: data.field_show_work_schedule[0]?.value,
    field_work_schedule_days: data.field_work_schedule_days?.value,
    field_show_alert_msg: data.field_show_alert_msg[0]?.value,
    field_work_schedule_alert: data.field_work_schedule_alert[0]?.value,
    field_alert_message: data.field_alert_message[0]?.value,
    ...(isSectionTitleEditable && {
      field_websites_section_title: data.field_websites_section_title[0]?.value,
      field_awards_title: data.field_awards_title[0]?.value,
      field_colleagues_section_title: data.field_colleagues_section_title[0]?.value,
      field_resources_section_title: data.field_resources_section_title[0]?.value,
      field_languages_section_title: data.field_languages_section_title[0]?.value,
      field_testimonials_section_title:
        data.field_testimonials_section_title[0]?.value,
      field_social_links_section_title:
        data.field_social_links_section_title[0]?.value,
    }),
    field_profile_keywords: data.field_profile_keywords[0]?.value ?? '',
  };
  return formData;
};

export const getProfileByURI = async (
  alias: string
): Promise<ProfileProps | null> => {
  const api = baseAPICreator({ baseURL: '/' });
  try {
    const { data } = await api.get(alias);
    setValueInSession('metatag', data.metatag?.value);
    const isSectionTitleEditable = await configCatClient.getValueAsync(
      'sectionTitleEditable',
      false
    );
    const profile = convertProfile(data, isSectionTitleEditable);
    return profile;
  } catch (error) {
    const cachedProfile = getCurrentUserCachedProfile();
    // console.log('current profile', cachedProfile, alias)
    if (cachedProfile?.profile?.path?.alias === alias) {
      return cachedProfile?.profile;
    }
    console.log(error);
    return null;
  }
};

interface UserStatusResponse {
  assign_replacement_person_uid?: string | null;
  assign_replacement_person_url?: string | null;
  assign_replacement_person_pronoun?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  email: string | null;
  last_login: string | null;
  status: string | null;
  uid: string | null;
  error: string | null;
  company: string | null;
  username: string | null;
}

export const getUserStatusByUserAlias = async (
  alias: string
): Promise<UserStatusResponse | null> => {
  const api = baseAPICreator({ baseURL: '/' });
  const currentProfile = getCurrentUserCachedProfile();
  try {
    const { data } = await api.get(`/user-by-url-alias${alias}`);
    const currentProfilePath = currentProfileCachePath();
    setValueInLocal(currentProfilePath, {
      ...currentProfile,
      [`/user-by-url-alias${alias}`]: data,
    });
    return data as UserStatusResponse;
  } catch (err) {
    if (currentProfile[`/user-by-url-alias${alias}`]) {
      return currentProfile[`/user-by-url-alias${alias}`];
    }
    return null;
  }
};

export const getProfileByUID = async (uid: string): Promise<ProfileProps | null> => {
  const api = baseAPICreator({ baseURL: '/user' });
  try {
    const { data } = await api.get(`/${uid}`);
    setValueInSession('metatag', data.metatag?.value);
    const isSectionTitleEditable = await configCatClient.getValueAsync(
      'sectionTitleEditable',
      false
    );
    const profile = convertProfile(data, isSectionTitleEditable);
    return profile;
  } catch (error) {
    const cachedProfile = getCurrentUserCachedProfile();
    if (cachedProfile?.profile?.uid === uid) {
      return cachedProfile?.profile;
    }
    console.log(error);
    return null;
  }
};

export const getProfileImage = async (uid: number): Promise<string | null> => {
  const api = baseAPICreator({
    baseURL: '',
    params: {
      _format: 'json',
      uid,
    },
    noAuth: true,
  });
  try {
    const { data } = await api.get('/profilepic');
    return data[0]?.field_profile_pic
      ? process.env.REACT_APP_BASE_API + data[0]?.field_profile_pic
      : null;
  } catch (error) {
    const cachedProfile = getCurrentUserCachedProfile();
    if (cachedProfile?.profile?.uid === uid) {
      return cachedProfile?.profileImgUrl;
    }
    console.log(error);
    return null;
  }
};

/**
 * GET request to get profile image thumbnail using user uid. This thumbnail is used in VCF
 * @param uid
 * @returns data base64 string
 */
export const getProfileThumbnailRequest = async (
  uid: number
): Promise<string | null> => {
  const api = baseAPICreator({
    baseURL: '',
    params: {
      _format: 'json',
      uid,
    },
    noAuth: true,
  });
  try {
    const { data } = await api.get('/profilepic-thumbnail');
    if (data[0]?.field_profile_pic) {
      return process.env.REACT_APP_BASE_API + data[0]?.field_profile_pic;
    }
    return null;
  } catch (error) {
    const cachedProfile = getCurrentUserCachedProfile();
    if (cachedProfile?.profile?.uid === uid) {
      return cachedProfile?.profileThumbnailUrl;
    }
    console.log(error);
    return null;
  }
};

const replaceFalseWithEmptyString = (obj: any) => {
  const newObj = { ...obj };

  Object.entries(newObj).forEach(([key, value]) => {
    if (
      ((typeof value === 'boolean' && value === false) || value === null) &&
      !key.includes('visible')
    ) {
      newObj[key] = '';
    }
  });

  return newObj;
};

export const updateProfileAPI = async (
  uid: number,
  body: any
): Promise<ProfileProps | null> => {
  const api = baseAPICreator({
    baseURL: '/user',
    params: {
      _format: 'hal_json',
    },
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  try {
    await api.patch(`/${uid}`, body);
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getCompanyEntity = async (url: string) => {
  const api = baseAPICreator({
    baseURL: '',
    params: {
      _format: 'json',
    },
  });
  const currentProfile = getCurrentUserCachedProfile();
  try {
    const { data } = await api.get(url);
    const currentProfilePath = currentProfileCachePath();
    setValueInLocal(currentProfilePath, { ...currentProfile, [url]: data });

    return data;
  } catch (err) {
    if (currentProfile[url]) {
      return currentProfile[url];
    }
    return null;
  }
};

export const getRepeatableFields = async (uid: number) => {
  const api = baseAPICreator({
    baseURL: '',
    params: {
      _format: 'json',
      values: Date.now(),
    },
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    noAuth: true,
  });
  try {
    const { data } = await api.get(`/multi-fields/${uid}`);
    const processedData = {
      field_company_logos: data?.field_company_logos,
      field_awards_certs: data?.field_awards_certs?.map((award: any) =>
        replaceFalseWithEmptyString(award)
      ),
      field_campaign_events: data?.field_campaign_events,
      field_contact_detail_emails: data?.field_contact_detail_emails,
      field_contact_detail_numbers: data?.field_contact_detail_numbers,
      field_contact_detail_websites: data?.field_contact_detail_websites,
      field_custom_sections: data?.field_custom_sections?.map(
        (customSections: any) => replaceFalseWithEmptyString(customSections)
      ),
      field_work_schedule_days: data?.field_work_schedule_days,
      field_featured_links: data?.field_featured_links?.map((featuredLink: any) =>
        replaceFalseWithEmptyString(featuredLink)
      ),
      field_resources: data?.field_resources?.map((resource: any) =>
        replaceFalseWithEmptyString(resource)
      ),
      field_reordering: formatReorderResponse(data?.field_reordering || []),
      field_languages: data?.languages?.map((language: any) =>
        replaceFalseWithEmptyString(language)
      ),
      field_testimonials: data?.testimonials?.map((testimonial: any) =>
        replaceFalseWithEmptyString(testimonial)
      ),
    };
    return processedData;
  } catch (error) {
    console.log(error);
    const cachedProfile = getCurrentUserCachedProfile();
    if (cachedProfile?.profile?.uid === uid) {
      return {
        field_company_logos: cachedProfile?.field_company_logos,
        field_awards_certs: cachedProfile?.awardsCertifications,
        field_campaign_events: [],
        field_contact_detail_emails: cachedProfile?.repeatableEmailAddress,
        field_contact_detail_numbers: cachedProfile?.repeatablePhoneNumbers,
        field_contact_detail_websites: cachedProfile?.repeatableWebsites,
        field_custom_sections: cachedProfile?.customSections,
        field_featured_links: cachedProfile?.featuredLinks,
        field_resources: cachedProfile?.resources,
        field_reordering: cachedProfile?.field_reordering,
        field_work_schedule_days: cachedProfile?.field_work_schedule_days,
        field_languages: cachedProfile?.languages,
        field_testimonials: cachedProfile?.field_testimonials,
      };
    }
    return null;
  }
};

export const getCompanyLayoutForType = async (
  nid: number,
  layoutType: string
): Promise<number | null> => {
  const api = baseAPICreator({
    baseURL: '',
    params: {
      _format: 'json',
      nid,
      type: layoutType,
    },
    noAuth: true,
  });
  const currentProfile = getCurrentUserCachedProfile();
  try {
    const { data } = await api.get('/company-layouts-downloadable');
    const currentProfilePath = currentProfileCachePath();
    setValueInLocal(currentProfilePath, {
      ...currentProfile,
      [`company-layouts-downloadable-${layoutType}`]: data[0],
    });

    return data[0];
  } catch (error) {
    if (currentProfile[`company-layouts-downloadable-${layoutType}`]) {
      return currentProfile[`company-layouts-downloadable-${layoutType}`];
    }
    console.log(error);
    return null;
  }
};

export const getCompanyLogos = async (
  cid: number
): Promise<CompanyLogoEntity[] | null> => {
  const api = baseAPICreator({
    baseURL: '',
    params: {
      _format: 'json',
      cid,
    },
    noAuth: true,
  });
  const currentProfile = getCurrentUserCachedProfile();
  try {
    const { data } = await api.get('/company_logos');
    const currentProfilePath = currentProfileCachePath();
    setValueInLocal(currentProfilePath, {
      ...currentProfile,
      companyEntity: { ...currentProfile.companyEntity, companyLogos: data },
    });

    return data;
  } catch (error) {
    const cachedProfile = getCurrentUserCachedProfile();
    if (cachedProfile) {
      return cachedProfile?.companyEntity?.companyLogos;
    }
    console.log(error);
    return null;
  }
};

export interface TimeZoneOption {
  label: string;
  value: string;
}

export async function getAllTimezones() {
  try {
    const response = await axios.get('https://worldtimeapi.org/api/timezone');
    const timezones: string[] = response.data;

    const formattedTimezones: TimeZoneOption[] = timezones.map(
      (timezone: string) => ({
        label: timezone,
        value: timezone,
      })
    );

    return formattedTimezones;
  } catch (error) {
    console.error('Error fetching time zone list:', error);
  }
}

export const getIPConfigAddress = async () => {
  try {
    const response = await axios.get('https://ipapi.co/json/');
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getIPAddress = async () => {
  try {
    const response = await axios.get('https://api.ipify.org/?format=json');
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

import { PropsWithChildren, ReactElement } from 'react';
import classNames from 'classnames';
import { TextField, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import Modal from 'react-modal';
import { Formik, Form, ErrorMessage } from 'formik';
import { Button } from './Button';
import { StyledProps } from 'types/styled';
import { ReactComponent as CloseIcon } from 'images/icons/Close.svg';
import { useLayout } from 'hooks/useLayout';
import { resetPass } from '../apis/resetPass';
import * as Yup from 'yup';
import { ReactComponent as AlertIcon } from 'images/icons/Alert.svg';
import { useProfile } from 'hooks/useProfile';
import { useAnalytics } from 'pages/AnalyticsPage/hooks/useAnalytics';
import { CustomButtonType } from 'apis/analytics';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    margin: 20,
    background: 'white',
    boxShadow: '0px 8px 12px rgba(0, 0, 0, 0.15)',
    borderRadius: 16,
    padding: 40,
  },
  closeIcon: {
    position: 'absolute',
    right: 24,
    top: 24,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    alignItems: 'center',
  },
  title: {
    '&.MuiTypography-root': {
      marginTop: 40,
      fontSize: 30,
      '@media screen and (max-width: 370px)': {
        fontSize: 24,
      },
    },
  },
  closeButton: {
    '&.MuiButton-root': {
      width: 'auto',

      '& > p': {
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '20px',
        color: theme.palette.text.primary,
        textTransform: 'none',
      },
    },
  },
  error: {
    display: 'inline-flex',
    marginTop: 24,
    gap: 8,
  },
}));

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    padding: 'none',
    background: 'transparent',
    borderRadius: 16,
    width: '100%',
    maxWidth: 540,
  },
};

interface QRCodeModalProps extends StyledProps {
  isOpen: boolean;
  closeModal?: () => void;
  redirect?: (alias: string) => void;
  showConfirmationMessage?: () => void;
}

const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const PasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .matches(EMAIL_REGEX, 'Invalid email')
    .required('Email is required'),
});

export const ResetPasswordModal = ({
  isOpen,
  closeModal,
  redirect,
  className,
  showConfirmationMessage,
}: PropsWithChildren<QRCodeModalProps>): ReactElement => {
  const classes = useStyles();
  const theme = useTheme();
  const { setLoading } = useLayout();
  const { defaultTheme } = useProfile();
  const { trackCustomButtonTappedEvent } = useAnalytics(null);

  const onSubmit = async (values: any) => {
    setLoading(true);
    trackCustomButtonTappedEvent(CustomButtonType.recoveryPassword, {
      email: values.email,
      recoveryPasswordStepsType: 'Submit Recovery',
    });
    const data = await resetPass({
      mail: values.email,
    });
    if (data === null) {
      setLoading(false);
      showConfirmationMessage && showConfirmationMessage();
    } else {
      setLoading(false);
      showConfirmationMessage && showConfirmationMessage();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="QRCode Modal"
      ariaHideApp={false}
    >
      <div className={classNames(classes.container, className)}>
        {closeModal && (
          <CloseIcon
            cursor="pointer"
            width={20}
            height={20}
            className={classes.closeIcon}
            onClick={closeModal}
          />
        )}
        <Formik
          initialValues={{
            email: '',
          }}
          onSubmit={(values) => onSubmit(values)}
          validationSchema={PasswordSchema}
        >
          {({ values, handleChange, handleBlur, errors, touched }) => (
            <Form className={classes.form}>
              <Typography
                fontWeight={600}
                lineHeight="40px"
                whiteSpace="nowrap"
                color="text.primary"
                className={classes.title}
              >
                Reset Your Password
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={400}
                lineHeight="24px"
                color="text.primary"
                style={{ textAlign: 'center' }}
              >
                You will receive an email to reset your password if the email is
                associated with a valid account.
              </Typography>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                type="email"
              />

              {errors.email && touched.email && (
                <div className={classes.error}>
                  <AlertIcon width={24} height={24} />
                  <Typography
                    fontSize={16}
                    fontWeight={400}
                    lineHeight="24px"
                    color={theme.palette.error.main}
                  >
                    <ErrorMessage name="email" />
                  </Typography>
                </div>
              )}

              <Button
                type="submit"
                title="SUBMIT"
                ctaColor={theme.palette.common.white}
                bgCtaColor={defaultTheme?.ctaColor}
              />

              <Button
                className={classes.closeButton}
                variant="text"
                title="Cancel"
                onClick={closeModal}
              />
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};
